"use client";

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import UserProfileShow from "@/app/(main)/_user_components/user-profile-show";
import { editMyProfile } from "@/app/api/dynabuy-back/api-profile";
import Button from "@/components/button";
import ClientFlash from "@/components/global/client-flash";
import ComponentLoading from "@/components/global/component-loading";
import FileInput from "@/components/global/inputs/file-input";
import SelectInput from "@/components/global/inputs/select-input";
import TextInput from "@/components/global/inputs/text-input";
import TextAreaInput from "@/components/global/inputs/textarea-input";
import SubSeparator from "@/components/global/sub-separator";
import ProfileText from "@/components/profile-text";
import { sidebarContext } from "@/context/sidebar-context";
import useMobile from "@/hooks/use-mobile";
import useSubmit from "@/hooks/use-submit";
import useProfile from "@/hooks/useProfile";
import { Person, User, UserProfileEditProps } from "@/TS/Interfaces/global_interfaces";
import { handleErrors } from "@/utils/formFunc/form-func";
export default function UserProfileEdit(props: UserProfileEditProps) {
  const [data, setData] = useState<Person>(undefined);
  const isMobile = useMobile();
  const profile = useProfile();
  const [street, setStreet] = useState();
  const {
    setVal: setFlashVal
  } = useContext(sidebarContext);
  const {
    errors,
    loading,
    onSubmit,
    setVal
  } = useSubmit(editMyProfile, {
    callbackMessage: {
      message: "La modification a été appliquée avec succès !",
      type: "success2",
      position: "user-profile-show"
    },
    sidebarJsx: <UserProfileShow />,
    errorFlashPath: "/mes-clubs#profile"
  });
  const {
    register,
    reset,
    unregister,
    setValue,
    handleSubmit
  } = useForm<Person>();
  const handleStreets = (city, citycode, street) => {
    setValue("organization.postalAddress.zipCode", citycode);
    setValue("organization.postalAddress.street", street);
    setValue("organization.postalAddress.city", city);
  };
  useEffect(() => {
    setData(profile);
    reset(data);
    unregister("organization.businessIdentifier");
  }, [profile]);
  if (!profile) return <ComponentLoading></ComponentLoading>;
  return <>
      {data && profile && <>
          {!isMobile ? <>
              <h1 className={"text-4xl font-bold md:mb-12 mb-6 text-center"}>
                Mon profil
              </h1>
              <SubSeparator label={"MES INFORMATIONS PERSONNELLES"}></SubSeparator>
            </> : <>
              <h1 className={"text-4xl font-bold md:mb-12 mb-6 text-center"}>
                Mon profil
              </h1>
            </>}
          <form onSubmit={handleSubmit(data => {
        onSubmit(data, undefined);
      })} className="flex w-full gap-8 flex-col">
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <div className="flex w-full items-center md:flex-row flex-col  justify-center  md:justify-start gap-4">
                <div className="flex gap-2 flex-col">
                  {!isMobile && <>
                      <p>
                        Civilité <span className={"text-primary"}>*</span>
                      </p>
                      <div className="flex gap-4">
                        <TextInput name={"civility"} type={"radio"} register={register} checked={data.civility === "madam" ?? false} value={"madam"} label={"Madame"} radioName={"civility"} error={errors?.propertyPath?.civility} disabled={true}></TextInput>{" "}
                        <TextInput name={"civility"} type={"radio"} checked={data.civility === "mister" ?? false} register={register} options={{
                    required: true
                  }} label={"Monsieur"} value={"mister"} radioName={"civility"} disabled={true}></TextInput>
                      </div>
                    </>}
                </div>
              </div>
              <FileInput type={"file"} isAvatar={true} label={"NOM"} firstName={data?.firstName} lastName={data?.lastName} imageUrl={data?.avatar?.urls?.avatar_md} name={"avatar.urls.avatar_md"}></FileInput>
            </div>
            <div className="flex gap-2 flex-col">
              {isMobile && <>
                  <SubSeparator label={"MES INFORMATIONS PERSONNELLES"}></SubSeparator>
                  <p>
                    Civilité <span className={"text-primary"}>*</span>
                  </p>
                  <div className="flex gap-4">
                    <TextInput name={"civility"} type={"radio"} register={register} value={"madam"} label={"Madame"} radioName={"civility"} error={errors?.propertyPath?.civility} disabled={true}></TextInput>{" "}
                    <TextInput name={"civility"} type={"radio"} register={register} options={{
                required: true
              }} label={"Monsieur"} value={"mister"} radioName={"civility"} disabled={true}></TextInput>
                  </div>
                </>}
            </div>
            <div className="flex md:flex-row flex-col  w-full items-center justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "firstName")} register={register} label={"Saisir le Prénom"} value={data?.firstName} placeholder={"Prénom"} name={"firstName"}
          // disabled={true}
          ></TextInput>
              <TextInput loading={loading} name={"lastName"} error={handleErrors(errors, "lastName")} label={"Saisir le Nom"} register={register} value={data?.lastName} placeholder={"placeholder"}
          // disabled={true}
          ></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "phone.number")} type={"tel"} label={"Téléphone"} value={data?.phone.number} name={"phone.number"} register={register} placeholder={"Saisir le Téléphone"} required={true}></TextInput>
              <TextInput loading={loading} error={handleErrors(errors, "")} type={"email"} label={"E-mail"} value={data?.email} name={"email"} register={register} placeholder={"exemple@gmail.com"} disabled={true}></TextInput>
            </div>
            <SubSeparator className={"mb-0"} label={"MON ENTREPRISE"}></SubSeparator>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput error={handleErrors(errors, "")} loading={loading} label={"Raison sociale"} value={data.organization?.name ?? ""} register={register} name={"organization.name"} placeholder={"Saisir la raison sociale"} disabled={true}></TextInput>
              <TextInput loading={loading} error={handleErrors(errors, "organization.brand")} label={"Nom commercial"} needed={false} value={data.organization?.brand ?? ""} nextLabel={"(si différent de la raison sociale) "} name={"organization.brand"} placeholder={"Saisir le nom commercial"} register={register}></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "")} label={"N° Siret"} register={register} value={data.organization?.businessIdentifier ?? ""} name={"organization.businessIdentifier"} placeholder={"Saisir le N° Siret"} disabled={true}></TextInput>
              <TextInput loading={loading} value={data.organization?.associationIdentifier ?? ""} error={handleErrors(errors, "organization.associationIdentifier")} label={"Saisir le N° RNA"} register={register} disabled={true} disabledClassName={"h-10"}
          // value={datas.organization?.associationIdentifier}
          name={"organization.associationIdentifier"} placeholder={"Saisir le N° RNA"}></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "organization.activity")} register={register} label={"Activité"} value={data.organization?.activity ?? ""} name={"organization.activity"} placeholder={"Saisir l'activité"}
          // disabled={true}
          ></TextInput>
              <TextInput loading={loading} error={handleErrors(errors, "jobTitle")} register={register} label={"Fonction"} required={true} value={data?.jobTitle} name={"jobTitle"} placeholder={"Saisir la fonction"}></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "organization.size")} type={"number"} label={"Effectif"} register={register} required={true} value={data.organization?.size ?? ""} name={"organization.size"} options={{
            valueAsNumber: true
          }} subLabel={"Salariés"} placeholder={"Saisir l'effectif"}></TextInput>
              <TextInput loading={loading} error={handleErrors(errors, "organization.capital")} type={"number"} label={"Capital"} needed={false} register={register} value={data.organization?.capital} name={"organization.capital"} placeholder={"Saisir le capital"} subLabel={"€"}></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "organization.postalAddress.street")} handleStreets={handleStreets} streets={true} register={register} required={true} label={"Adresse"} value={data.organization?.postalAddress.street} name={"organization.postalAddress.street"} placeholder={"Saisir l'adresse"}></TextInput>
              <TextInput loading={loading} error={handleErrors(errors, "organization.postalAddress.zipCode")} handleStreets={handleStreets} label={"Code postal"} required={true} register={register} value={data.organization?.postalAddress.zipCode} name={"organization.postalAddress.zipCode"} placeholder={"Saisir le code postal"} streets={true}></TextInput>
            </div>
            <div className="flex w-full items-center md:flex-row flex-col  justify-center gap-6 md:gap-16">
              <TextInput loading={loading} error={handleErrors(errors, "organization.postalAddress.city")} label={"Ville"} handleStreets={handleStreets} required={true} register={register} value={data.organization?.postalAddress.city} name={"organization.postalAddress.city"} streets={true} placeholder={"Saisir la ville"}></TextInput>{" "}
              <SelectInput name={"organization.postalAddress.country"} required={true} label={"Pays"} register={register} needed={true} value={data.organization?.postalAddress.country ?? ""} error={handleErrors(errors, "organization.postalAddress.country")}>
                <option value="" disabled>
                  Sélectionner
                </option>
                <option value="FR">France</option>
                <option value="BE">Belgique</option>
                <option value="CH">Suisse</option>
                <option value="OTHER">Autres</option>
              </SelectInput>
            </div>
            <div className="flex w-full md:w-1/2   items-center justify-center gap-6 md:gap-16">
              <TextInput label={"Site web"} register={register} loading={loading} needed={false} error={handleErrors(errors, "organization.website")} value={data.organization?.website} name={"organization.website"} placeholder={"Saisir le site web"}></TextInput>{" "}
            </div>
            {/*Masqué pour le MVP 11/10/2024 Victor*/}
            {/*<div className="flex md:w-1/2 w-full  items-center justify-center gap-6 md:gap-16">*/}
            {/*  <FileInput*/}
            {/*    label={"Logo"}*/}
            {/*    type={"file"}*/}
            {/*    imageUrl={data.organization.logo?.urls?.logo_md}*/}
            {/*    name={"organization.logo.urls.logo_md"}*/}
            {/*  ></FileInput>{" "}*/}
            {/*</div>*/}
            <SubSeparator className={"mt-8"} label={"RÉSEAUX SOCIAUX"}></SubSeparator>
            <div className="flex w-full flex-col md:flex-row  items-center justify-center gap-6 md:gap-16">
              <TextInput register={register} label={"Profil Linkedin"} loading={loading} error={handleErrors(errors, "socialNetworks.linkedin")} value={data?.socialNetworks?.linkedin ?? ""} name={"socialNetworks.linkedin"} placeholder={"Saisir votre profil linkedin"} needed={false}></TextInput>{" "}
              <TextInput label={"Profil Facebook"} register={register} value={data?.socialNetworks?.facebook ?? ""} name={"socialNetworks.facebook"} needed={false} placeholder={"Saisir votre profil facebook"} loading={loading} error={handleErrors(errors, "socialNetworks.facebook")}></TextInput>{" "}
            </div>
            <div className="flex w-full flex-col md:flex-row  items-center justify-center gap-6 md:gap-16">
              <TextInput label={"Profil Instagram"} register={register} value={data?.socialNetworks?.instagram ?? ""} needed={false} name={"socialNetworks.instagram"} placeholder={"Saisir votre profil instagram"} loading={loading} error={handleErrors(errors, "socialNetworks.instagram")}></TextInput>{" "}
              <div className="md:w-full md:visible invisible "></div>
            </div>
            <div className={"mt-12 relative flex flex-col"}>
              <>
                <ProfileText type={"presentation"}>
                  <div className={"flex"}>
                    <TextAreaInput register={register} rows={5} error={handleErrors(errors, "aboutMe")} id={"whoIAm"} defaultValue={data?.aboutMe} name={"aboutMe"} {...register("aboutMe")} placeholder={"Mon parcours, ce qui m’amène à mon activité actuelle, mes passions, mes engagements sportifs ou associatifs…"} classNameTextarea={twMerge("bg-info border-none")} className={twMerge(` grow rounded  !bg-info rounded-b`)}></TextAreaInput>
                  </div>
                </ProfileText>
                <ProfileText type={"propose"}>
                  <div className={"flex"}>
                    <TextAreaInput register={register} rows={5} error={handleErrors(errors, "mySuggestion")} id={"whoIAm"} defaultValue={data?.mySuggestion} name={"mySuggestion"} {...register("mySuggestion")} placeholder={"Mon parcours, ce qui m’amène à mon activité actuelle, mes passions, mes engagements sportifs ou associatifs…"} classNameTextarea={twMerge("bg-info border-none")} className={twMerge(` grow rounded  !bg-info rounded-b`)}></TextAreaInput>
                  </div>
                </ProfileText>
                <ProfileText type={"expect"}>
                  <div className={"flex"}>
                    <TextAreaInput register={register} rows={5} error={handleErrors(errors, "expectOfYou")} id={"whoIAm"} defaultValue={data?.expectOfYou} name={"expectOfYou"} {...register("expectOfYou")} placeholder={"Mon parcours, ce qui m’amène à mon activité actuelle, mes passions, mes engagements sportifs ou associatifs…"} classNameTextarea={twMerge("bg-info border-none")} className={twMerge(` grow rounded  !bg-info rounded-b`)}></TextAreaInput>
                  </div>
                </ProfileText>
              </>

              <ClientFlash path={"/mes-clubs#profile"} />

              <div className="flex flex-col-reverse md:flex-row self-center items-center md:w-56 w-full justify-center gap-6">
                <Button colorTheme={"secondary"} variant={"unfilled"} className={"md:min-w-full w-full py-3"} type={"button"} onClick={() => setVal(<UserProfileShow />)}>
                  Annuler
                </Button>
                <Button colorTheme={"primary"} variant={"filled"} className={"md:min-w-full w-full py-3"} type={"submit"}>
                  Enregistrer
                </Button>
              </div>
            </div>
          </form>
        </>}
    </>;
}