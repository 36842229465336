"use client";

import { ProfileTextProps } from "@/TS/Interfaces/global_interfaces";
export default function ProfileText({
  type,
  children
}: ProfileTextProps) {
  let title,
    subtitle = "";
  switch (type) {
    case "presentation":
      title = "Je me présente";
      subtitle = "mieux me connaître personnellement";
      break;
    case "propose":
      title = "Ce que je propose";
      subtitle = "mieux me connaître professionnellement";
      break;
    case "expect":
      title = "Ce que j'attends des membres";
      subtitle = "pour mieux me recommander";
  }
  return <div className={`mb-8`} data-sentry-component="ProfileText" data-sentry-source-file="profile-text.tsx">
      <div className={"bg-info rounded-t p-4 mb-2"}>
        <h3 className={"text-lg"}>
          <span className={"font-bold"}>{title}</span> ({subtitle})
        </h3>
      </div>
      <div className={"bg-info rounded-b text-wrap p-6 overflow-hidden text-ellipsis"}>
        {children}
      </div>
    </div>;
}