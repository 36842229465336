import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import ErrorsInput from "@/components/global/inputs/errors-input";
import Spinner from "@/components/global/spinner";
import Icon from "@/components/icon";
import { TextAreaInputProps } from "@/TS/Interfaces/global_interfaces";

/**
 * A custom input textarea who work with useForm by passing register={register} and a name with name={name}
 *
 * @remarks
 * [useForm docs](https://react-hook-form.com/docs/useform)
 *
 */
function TextAreaInput({
  name,
  error,
  disabled = false,
  required = false,
  loading,
  needed = true,
  register,
  className,
  classNameTextarea,
  labelClassName,
  options,
  placeholder,
  label,
  value,
  rows,
  maxLength,
  id,
  ...rest
}: TextAreaInputProps) {
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
  const handleChange = value => {
    setChanged(true);
  };
  useEffect(() => {
    setChanged(false);
  }, [error]);
  useEffect(() => {
    handleChange(value);
    setChanged(false);
  }, []);
  useEffect(() => {
    setVal(value);
  }, [value]);
  return <div className={"w-full relative"} data-sentry-component="TextAreaInput" data-sentry-source-file="textarea-input.tsx">
      {label && <label className={"text-md " + labelClassName ?? ""} htmlFor={id}>
          {label} {needed && <span className={"text-primary "}>*</span>}{" "}
        </label>}
      <div className={`flex mt-1 items-center justify-center relative ${className ?? ""}`}>
        <textarea rows={rows} maxLength={maxLength} defaultValue={value} {...register(name, {
        onChange: e => handleChange(e.target.value),
        ...options
      } ?? undefined)} autoComplete={"on"} name={name} className={twMerge("p-4 grow rounded border", error?.err && !changed && "text-primary bg-danger3 border-primary", classNameTextarea)} required={required} disabled={disabled} placeholder={placeholder} {...rest} />
        {loading && <Spinner></Spinner>}
        {error?.err && !changed && <Icon icon={"cross"} size={25} className={"absolute right-2 text-primary"} />}
        {error?.err === false && !changed && <Icon icon={"check-simple"} size={25} className={"absolute right-2 text-success2"} />}
      </div>
      <ErrorsInput changed={changed} error={error} data-sentry-element="ErrorsInput" data-sentry-source-file="textarea-input.tsx" />
    </div>;
}
export default React.memo(TextAreaInput);