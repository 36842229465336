"use client";

import Link from "next/link";
import React, { useContext, useEffect } from "react";
import UserKeyData from "@/app/(main)/_user_components/user-key-data";
import UserProfileEdit from "@/app/(main)/_user_components/user-profile-edit";
import Flash from "@/components/flash";
import ComponentLoading from "@/components/global/component-loading";
import ProfileText from "@/components/profile-text";
import SocialLink from "@/components/social-link";
import ProfilePicture, { CustomTooltip } from "@/components/user/profile-picture";
import { flashContext } from "@/context/flash-context";
import { sidebarContext } from "@/context/sidebar-context";
import useProfile from "@/hooks/useProfile";
import { formatter } from "@/lib/formatter";
import Button from "../../../components/button";
export default function UserProfileShow(props) {
  // TODO afficher les profils des utilisateurs

  const profile = useProfile();
  let publishedLeads = profile && (profile.publishedLeads || profile.countPublishedLeads) ? profile.publishedLeads || profile.countPublishedLeads : 0;
  let transformedLeads = profile && (profile.transformedLeads || profile.countTransformedLeads) ? profile.transformedLeads || profile.countTransformedLeads : 0;
  let invitationsDispatched = profile && (profile.invitationsDispatched || profile.countInvitationsDispatched) ? profile.invitationsDispatched || profile.countInvitationsDispatched : 0;
  let avatar = profile && profile.avatar?.urls?.avatar_md;
  let firstName = profile && profile.firstName ? profile.firstName : "";
  let lastName = profile && profile.lastName ? profile.lastName : "";
  const {
    flashContextValue
  } = useContext(flashContext);
  const {
    val,
    setVal,
    setIsHide,
    setJsxValue
  } = useContext(sidebarContext);
  useEffect(() => {
    setJsxValue("");
  }, []);
  if (!profile) return <ComponentLoading></ComponentLoading>;
  return <>
      {flashContextValue.position === "user-profile-show" && <Flash type={flashContextValue.type}>{flashContextValue.message}</Flash>}
      <div className={"flex gap-6 flex-col items-center "}>
        <header className={"flex w-full flex-wrap gap-4 md:gap-6 flex-col md:flex-row"}>
          <div className="flex relative">
            <ProfilePicture size={64} src={avatar} firstName={firstName} lastName={lastName} data-sentry-element="ProfilePicture" data-sentry-source-file="user-profile-show.tsx" />
            <div className={"flex flex-col mx-2"}>
              <h2 className={"font-bold tracking-wide text-2xl"}>
                {firstName} <span className={"uppercase"}>{lastName}</span>
              </h2>
              <div className={"text-sm text-nowrap"}>
                {profile && profile.jobTitle ? profile.jobTitle : ""}
              </div>
              <div className={"flex gap-1"}>
                {profile && profile.phone && profile.phone.countryCode && profile.phone.number && <CustomTooltip title={profile.phone.countryCode + profile.phone.number}>
                      <SocialLink type={"phone"} href={`phone:${profile.phone.countryCode + profile.phone.number}`} />
                    </CustomTooltip>}
                {profile.email && <CustomTooltip title={profile.email}>
                    <SocialLink iconSize={35} type="email" href={profile.email} />
                  </CustomTooltip>}
                {profile?.socialNetworks?.facebook && <SocialLink href={profile.socialNetworks.facebook} iconSize={35} type={"facebook"}></SocialLink>}
                {profile?.socialNetworks?.instagram && <SocialLink href={profile.socialNetworks.instagram} iconSize={35} type={"instagram"}></SocialLink>}
                {profile?.socialNetworks?.linkedin && <SocialLink href={profile.socialNetworks.linkedin} iconSize={35} type={"linkedin"}></SocialLink>}
              </div>
            </div>
          </div>

          {/*<div className={"flex flex-col md:flex-row gap-2 h-fit"}>*/}
          {/*  <UserKeyData*/}
          {/*    icon={"lead"}*/}
          {/*    keyNumber={publishedLeads}*/}
          {/*    className={"md:mx-2"}*/}
          {/*  >*/}
          {/*    Leads publiés*/}
          {/*  </UserKeyData>*/}
          {/*  <UserKeyData*/}
          {/*    icon={"trophy"}*/}
          {/*    keyNumber={transformedLeads}*/}
          {/*    className={"md:mx-2 border-light-yellow"}*/}
          {/*    bgClass={"bg-light-yellow"}*/}
          {/*  >*/}
          {/*    Leads transformés*/}
          {/*  </UserKeyData>*/}
          {/*  <UserKeyData*/}
          {/*    icon={"invitation"}*/}
          {/*    keyNumber={invitationsDispatched}*/}
          {/*    iconSize={28}*/}
          {/*    className={"md:mx-2 border-histo-blue-light"}*/}
          {/*    bgClass={"bg-histo-blue-light"}*/}
          {/*  >*/}
          {/*    Invitations envoyées*/}
          {/*  </UserKeyData>*/}
          {/*</div>*/}
        </header>
        <div className={"flex md:flex-row flex-col w-full"}>
          {/*<div className={"w-1/6"}>*/}
          {/*  /!*TODO Ajouter l'image de l'entreprise qd dispo via API*!/*/}
          {/*</div>*/}
          <div className={"w-3/4 ml-[72px]"}>
            {profile && profile.organization && <>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>
                    Nom de l'entreprise :
                  </span>
                  {profile.organization.name}
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>N° SIRET :</span>
                  {profile.organization.businessIdentifier}
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Activité :</span>
                  {profile.organization.activity}
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Fonction :</span>
                  {profile.jobTitle}
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Effectif :</span>
                  {profile.organization.size} salarié(s)
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Capital :</span>
                  {formatter.integer(profile.organization.capital)} €
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Adresse :</span>
                  {profile.organization.postalAddress.street}{" "}
                  {profile.organization.postalAddress.zipCode}{" "}
                  {profile.organization.postalAddress.city}
                  {/*-{" "}*/}
                  {/*{profile.organization.postalAddress.country}*/}
                </div>
                <div className={"mb-2"}>
                  <span className={"font-bold mr-2"}>Site web :</span>
                  {profile.organization.website ? <Link href={profile.organization.website} target={"_blank"} className={"underline hover:no-underline"}>
                      {profile.organization.website}
                    </Link> : "Aucun site renseigné"}
                </div>
              </>}
          </div>
        </div>
        <div className={"w-full"}>
          {profile && <>
              <ProfileText type={"presentation"}>{profile.aboutMe}</ProfileText>
              <ProfileText type={"propose"}>{profile.mySuggestion}</ProfileText>
              <ProfileText type={"expect"}>{profile.expectOfYou}</ProfileText>
            </>}
        </div>
        <div className={"flex flex-col-reverse md:flex-row w-full justify-center items-center w-56 gap-4"}>
          <Button type={"button"} variant={"unfilled"} colorTheme={"secondary"} className={" py-3  w-full md:w-60 "} onClick={() => {
          setVal("");
          setIsHide(false);
        }} data-sentry-element="Button" data-sentry-source-file="user-profile-show.tsx">
            Fermer
          </Button>
          <Button type={"submit"} className={"my-6  py-3 mx-1 md:mx-2 w-full md:w-60  text-nowrap"} onClick={() => {
          setVal(<UserProfileEdit />);
          setIsHide(false);
        }} data-sentry-element="Button" data-sentry-source-file="user-profile-show.tsx">
            Mettre à jour mon profil
          </Button>
        </div>
      </div>
    </>;
}