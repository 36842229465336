"use client";

import React, { createContext, useState } from "react";
export const breadcrumbContext = createContext();
function BreadcrumbContext({
  children
}) {
  const [value, setValue] = useState([{
    href: "/",
    label: "Accueil"
  }]);
  return <breadcrumbContext.Provider value={[value, setValue]} data-sentry-element="unknown" data-sentry-component="BreadcrumbContext" data-sentry-source-file="breadcrumb-context.jsx">
      {children}
    </breadcrumbContext.Provider>;
}
export default BreadcrumbContext;